import { render, staticRenderFns } from "./AgentModal.vue?vue&type=template&id=5604b56a&scoped=true&"
import script from "./AgentModal.vue?vue&type=script&lang=js&"
export * from "./AgentModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5604b56a",
  null
  
)

export default component.exports