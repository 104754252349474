<template>
    <b-modal v-model="openedModal" centered hide-footer v-if="agent">
        <b-alert class="p-1 text-center" show variant="warning">
            {{ this.type === 'edit' ? 
                'Po spremembi agenta, je potrebnih nekaj sekund, da so spremembe vidne. Če sprememba agenta na seznamu niso vidne, poskusite osvežiti spletno stran.' :
                'Po dodajanju agenta, je potrebnih nekaj sekund, da je agent prikazan na seznamu. Če agenta na seznamu ne vidite, poskusite osvežiti spletno stran.'
            }}
        </b-alert>
        <validation-observer ref="validateAgent">
            <b-form class="form">
                <validation-provider #default="{ errors }" name="Ime" rules="required">
                    <b-form-group>
                        <label>Ime:</label>
                        <b-form-input
                            type="text"
                            placeholder="Ime"
                            v-model="agent.name"
                            name="name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="Priimek" rules="required">
                    <b-form-group>
                        <label>Priimek:</label>
                        <b-form-input
                            type="text"
                            placeholder="Priimek"
                            v-model="agent.surname"
                            name="surname"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="E-Naslov" rules="required|email|max:50">
                    <b-form-group>
                        <label>Email:</label>
                        <b-form-input
                            name="email"
                            type="text"
                            placeholder="E-Naslov"
                            v-model="agent.email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="Telefonska številka" rules="phone_number">
                    <b-form-group>
                        <label>Telefonska številka:</label>
                        <b-form-input
                            name="Telefon"
                            type="text"
                            placeholder="Telefonska številka"
                            v-model="agent.telephone_number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <b-form-group v-if="type === 'add'" class="text-right">
                    <b-button variant="secondary" @click="validationFormAddAgent">Dodaj</b-button>
                </b-form-group>
                <b-form-group v-if="type === 'edit'" class="text-right">
                    <b-button variant="secondary" @click="validationFormEditAgent">Uredi</b-button>
                </b-form-group>
         
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {BModal, BFormGroup, BFormInput, BButton, BAlert, BForm} from 'bootstrap-vue'
    import { required, email, max, PhoneNumber } from '@validations'
    export default {
        components: {
            BModal,
            BFormGroup,
            BFormInput,
            BButton,
            BAlert,
            BForm,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            type: {
                type: String,
                reqired: true
            },
            agent: {
                type: Object,
                reqired: false
            }
        },
        data() {
            return {
                PhoneNumber,
                openedModal: false,
                text: 'Dodaj',
                required, 
                email,
                max
            }
        },
        methods: {
            openModal() {
                if (this.type === 'edit') {
                    this.text = 'Uredi'
                }
                this.openedModal = true
            },
            
            validationFormAddAgent() {
                this.$refs.validateAgent.validate().then(success => {
                    if (success) {
                        this.addRealEstateAgent()
                    }
                })
            },
            validationFormEditAgent() {
                this.$refs.validateAgent.validate().then(success => {
                    if (success) {
                        this.editRealEstateAgent()
                    }
                })
            },
            addRealEstateAgent() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/real_estate/agent/', this.agent)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Agent je bil dodan')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            editRealEstateAgent() {
                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/real_estate/agent/${this.agent.id}`, this.agent)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Agent je bil uspešno urejen')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.message)
                })
            }
           
        }
    }
</script>

<style scoped>

</style>
