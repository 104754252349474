<template>
    <b-overlay :show="showLoader">
        <a class="mb-1 d-flex justify-content-end align-items-center text-primary" @click="removeFilters">
            Odstrani filtre <fa class="ml-1" icon="times" />
        </a>
        <b-table
            id="table"
            :responsive="true"
            style="position: relative;"
            striped
            bordered
            hover
            :items="fromElastic ? data : filtered"
            :fields="fields"
            :per-page="fromElastic ? 0 : pagination.per_page"
            :current-page="pagination.current_page"
        >
            <template slot="top-row" slot-scope="{ fields }">
                <b-td v-for="field in fields" :key="field.key">
                    <b-form-select v-if="field.type === 'select'" v-model="filters[field.key]" :options="field.options" size="sm" @change="getQuerySelect(filters[field.key], field.key)"/>
                    <b-form-select v-else-if="field.type === 'bool'" v-model="filters[field.key]" :options="boolOptions" size="sm" @change="getQuerySelect(filters[field.key], field.key)"/>
                    <span v-else-if="field.key === 'actions' || field.key === 'main_photo' || field.key === 'documents'"/>
                    <cleave v-else-if="field.type === 'date'" :raw="false" v-model="filters[field.key]" class="form-control form-control-sm" :options="date" placeholder="DD-MM-YYYY" @keyup.native="getQueryDate(filters[field.key], field.key)"/>
                    <b-form-input v-else size="sm" v-model="filters[field.key]" :placeholder="'Išči (' + field.label.toLowerCase() + ')'" @keyup="getQueryString(filters[field.key], field.key)"/>
                </b-td>
            </template>
            <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                <slot v-if="data && data.length > 0" :name="slotName" v-bind="scope"/>
            </template>
            <template v-if="data.length === 0" slot="bottom-row">
                <b-td :colspan="fields.length">
                    <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                </b-td>
            </template>
        </b-table>
        <template>
            <b-row v-if="pagination && pagination.per_page > 0" class="mt-2">
                <b-col class="text-center text-sm-left">
                    <div class="d-flex flex-column flex-sm-row align-items-center">
                        <h5 class="mb-0 mr-2">Skupaj: {{fromElastic ? pagination.total_items : data.length}}</h5>
                        <b-dropdown id="table-dropdown" variant="outline" :text="'Na stran: ' + pagination.per_page" size="sm" class="btn-none mt-1 mt-sm-0">
                            <b-dropdown-item v-model="pagination.per_page" v-for="(item, key) in pageOptions" :key="key" @click="pagination.per_page = item">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-col>
                <b-col>
                    <b-pagination class="mt-1 mt-sm-0 justify-content-center justify-content-sm-end" id="table-pagination" pills v-model="pagination.current_page" :total-rows="pagination.total_items" :per-page="pagination.per_page" size="sm"/>
                </b-col>
            </b-row>
        </template>
    </b-overlay>
</template>
<script>
    import Cleave from 'vue-cleave-component'
    import {
        BOverlay,
        BRow,
        BCol,
        BPagination,
        BTable,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BFormSelect,
        BTd
    } from 'bootstrap-vue'
    export default {
        components: {
            Cleave,
            BOverlay,
            BRow,
            BCol,
            BPagination,
            BTable,
            BFormInput,
            BDropdown,
            BDropdownItem,
            BFormSelect,
            BTd
        },
        props: {
            fields: {
                type: Array,
                required: true
            },
            route: {
                type: String
            },
            fromElastic: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showLoader: false,
                pageOptions: [10, 20, 50, 100],
                filters: [],
                data: [],
                filter: [],
                pagination: { current_page: 1, per_page: 10, total_items: 0 },
                boolOptions: [
                    { value: null, text: '---' },
                    { value: true, text: 'Da' },
                    { value: false, text: 'Ne' }
                ],
                queryParams: [],
                searchIndex: '',
                date: {
                    date: true,
                    delimiter: '.',
                    datePattern: ['d', 'm', 'Y']
                },
                awaitingInput: false,
                created: false
            }
        },
        methods: {
            getQuerySelect(searchValue, fieldName) {
                if (searchValue !== '' && searchValue !== null) {
                    this.filter[fieldName] = `&${fieldName}.[]=${searchValue}&`
                } else {
                    this.$delete(this.filter, fieldName)
                }
                this.search()
            },
            getQueryDate(searchValue, fieldName) {
                if (
                    searchValue &&
                    searchValue !== '' &&
                    searchValue !== null &&
                    searchValue.length === 10
                ) {
                    const searchFrom = this.moment(searchValue, 'DD.MM.YYYY').utc(+1).format()
                    const searchTo = this.moment(searchValue, 'DD.MM.YYYY').utc(+1).add(1, 'days').format()
                    this.filter[`${fieldName}gte`] = `&${fieldName}.gte=${searchFrom}&`
                    this.filter[`${fieldName}lt`] = `&${fieldName}.lt=${searchTo}&`
                    this.search()
                } else if (!searchValue) {
                    this.$delete(this.filter, `${fieldName  }gte`)
                    this.$delete(this.filter, `${fieldName  }lt`)
                    this.search()
                }
            },
            getQueryString(searchValue, fieldName) {
                if (
                    searchValue &&
                    searchValue !== '' &&
                    searchValue !== null &&
                    searchValue.length > 1
                ) {
                    this.filter[fieldName] = `&${fieldName}=${searchValue}&`
                    this.searchTimeout()
                } else if (!searchValue) {
                    this.$delete(this.filter, fieldName)
                    this.searchTimeout()
                }
            },
            searchTimeout() {
                if (this.awaitingInput) {
                    clearTimeout(this.awaitingInput)
                    this.awaitingInput = true
                }
                this.awaitingInput = setTimeout(() => {
                    this.search()
                }, 700)
            },
            getQueryParams() {
                const page = this.filters.page ?? 1
                const perPage = this.filters.perPage ?? 10
                this.filter.page = `&page=${page}&`
                this.filter.perPage = `&perPage=${perPage}&`
                const queries = []

                if (this.$route.name === 'admin-list') {
                    this.filter['administrator'] = '&administrator.[]=true&'
                } else if (this.$route.name === 'user-list') {
                    this.filter['administrator'] = '&administrator.[]=false&'
                }

                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(this.filter)) {
                    queries.push(value)
                }

                return queries.join('')
            },
            search() {
                if (this.fromElastic) this.searchFromElastic()
                else this.loadData()
            },
            searchFromElastic() {
                const thisIns = this
                thisIns.showLoader = true
                const queryParams = thisIns.getQueryParams()

                this.$http
                    .get(`${this.route}?${queryParams}`)
                    .then((response) => {
                        thisIns.data = response.data.items ?? []
                        thisIns.pagination = response.data.pagination
                    })
                    .catch((error) => {
                        thisIns.$printError(error.response.data)
                    })
                    .finally(function() {
                        thisIns.showLoader = false
                    })
            },
            loadData() {
                const thisIns = this
                this.$http
                    .get(`${this.route}`)
                    .then((response) => {
                        thisIns.data = response.data ?? []
                        thisIns.pagination.total_items = this.data.length
                    })
                    .catch((error) => {
                        thisIns.$printError(error.response.data)
                    })
                    .finally(function() {
                        thisIns.showLoader = false
                    })
            },
            removeFilters() {
                this.filters = []
                this.filter = []
                this.awaitingInput = false
                if (this.fromElastic) this.searchFromElastic()
            }
        },
        mounted() {
            if (this.fromElastic) this.searchFromElastic()
            else this.loadData()
            this.created = true
        },
        computed: {
            filtered() {
                const filtered = this.data.filter((item) => {
                    return Object.keys(this.filters).every((key) => (typeof item[key] === 'boolean' && this.filters[key] !== null ? item[key] === this.filters[key] : this.filters[key] !== null ? String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()) : true))
                })
                return filtered.length > 0 ? filtered : []
            }
        },
        watch: {
            'pagination.current_page'(newVal) {
                if (!this.fromElastic || !this.created) return
                this.filters.page = newVal
                this.searchFromElastic()
            },
            'pagination.per_page'(newVal) {
                if (!this.fromElastic || !this.created) return
                this.filters.page = 1
                this.filters.perPage = newVal
                this.searchFromElastic()
            }
        }
    }
</script>
<style>
#table .form-control,
#table-dropdown,
#table-dropdown .dropdown-menu {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
#table-dropdown .dropdown-toggle::after,
.drop .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
#table .custom-checkbox .custom-control-label {
  position: relative;
}
</style>
