<template>
    <div>
        <b-overlay :show="showLoader">
            <b-card>
                <b-row>
                    <b-col cols="12" sm="6" class="text-center text-sm-left">
                        <b-button variant="primary" @click="onAddAgent" v-if="$hasPermissions($permissions.EditRealEstate)">Dodaj agenta</b-button>
                    </b-col>
                </b-row>
                <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je agent po brisanju še vedno viden, ponovno naložite stran.</b-alert>

                <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/es/real_estate_agents" :fromElastic="true">
                    <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditRealEstate)">
                        <div class="d-flex justify-content-center">
                            <b-button class="mr-1" variant="warning" @click="onEditAgent(row.item)"><fa icon="edit"/></b-button>
                            <b-button variant="danger" @click="deleteAgent(row.item.id)"><fa icon="trash"/></b-button>
                        </div>
                    </template>
                </Table>
            </b-card>
        </b-overlay>
        <agent-modal ref="addAgentModal" type="add" :agent="agentToAdd" @success="resetAgent(agentToAdd); getItems()" />
        <agent-modal ref="editAgentModal" type="edit" :agent="agentToEdit" @success="resetAgent(agentToEdit); getItems()" />
    </div>
</template>
<script>

    import Table from '@/views/Components/Table'
    import AgentModal from './AgentModal.vue'
    import {BAlert, BCol, BRow, BButton, BCard, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            AgentModal,
            BRow,
            BCol,
            BAlert,
            BButton,
            BCard,
            BOverlay
        },
        data() {
            return {
                showLoader: false,
                fields: [
                    { key: 'name', label: 'Ime', sortable: true, class: 'text-center' },
                    { key: 'surname', label: 'Priimek', sortable: true, class: 'text-center' },
                    { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
                    { key: 'telephone_number', label: 'Telefonska številka', sortable: true, class: 'text-center' }
                ],
                agentToAdd: {
                    name: '',
                    surname: '',
                    email: '',
                    telephone_number: ''
                },
                agentToEdit: null,
                hasBeenDeleted: false
            }
        },
        methods: {
            getItems() {
                setTimeout(function() {
                    this.$refs.table.search()
                }.bind(this), 1000)
            },
            resetAgent(agent) {
                agent.name = ''
                agent.surname =  ''
                agent.email =  ''
                agent.telephone_number =  ''
            },
            onAddAgent() {
                this.$refs.addAgentModal.openModal()
            },
            onEditAgent(agent) {
                this.agentToEdit = agent
                this.$refs.editAgentModal.openModal()
            },
            deleteAgent(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati tega nepremičninskega agenta.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.showLoader = true
                        this.$http.delete(`/api/management/v1/real_estate/agent/${id}`).then(function() {
                            thisIns.$printSuccess('Agent je bil uspešno izbrisan')

                            thisIns.hasBeenDeleted = true

                            setTimeout(() => {
                                thisIns.hasBeenDeleted = false
                            }, 10_000)

                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.$refs.table.search()
                            thisIns.showLoader = false
                        })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditRealEstate)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>
