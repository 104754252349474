var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.agent)?_c('b-modal',{attrs:{"centered":"","hide-footer":""},model:{value:(_vm.openedModal),callback:function ($$v) {_vm.openedModal=$$v},expression:"openedModal"}},[_c('b-alert',{staticClass:"p-1 text-center",attrs:{"show":"","variant":"warning"}},[_vm._v(" "+_vm._s(this.type === 'edit' ? 'Po spremembi agenta, je potrebnih nekaj sekund, da so spremembe vidne. Če sprememba agenta na seznamu niso vidne, poskusite osvežiti spletno stran.' : 'Po dodajanju agenta, je potrebnih nekaj sekund, da je agent prikazan na seznamu. Če agenta na seznamu ne vidite, poskusite osvežiti spletno stran.')+" ")]),_c('validation-observer',{ref:"validateAgent"},[_c('b-form',{staticClass:"form"},[_c('validation-provider',{attrs:{"name":"Ime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Ime:")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Ime","name":"name"},model:{value:(_vm.agent.name),callback:function ($$v) {_vm.$set(_vm.agent, "name", $$v)},expression:"agent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4183149821)}),_c('validation-provider',{attrs:{"name":"Priimek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Priimek:")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Priimek","name":"surname"},model:{value:(_vm.agent.surname),callback:function ($$v) {_vm.$set(_vm.agent, "surname", $$v)},expression:"agent.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1770626493)}),_c('validation-provider',{attrs:{"name":"E-Naslov","rules":"required|email|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Email:")]),_c('b-form-input',{attrs:{"name":"email","type":"text","placeholder":"E-Naslov"},model:{value:(_vm.agent.email),callback:function ($$v) {_vm.$set(_vm.agent, "email", $$v)},expression:"agent.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1982538960)}),_c('validation-provider',{attrs:{"name":"Telefonska številka","rules":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v("Telefonska številka:")]),_c('b-form-input',{attrs:{"name":"Telefon","type":"text","placeholder":"Telefonska številka"},model:{value:(_vm.agent.telephone_number),callback:function ($$v) {_vm.$set(_vm.agent, "telephone_number", $$v)},expression:"agent.telephone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,448360442)}),(_vm.type === 'add')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormAddAgent}},[_vm._v("Dodaj")])],1):_vm._e(),(_vm.type === 'edit')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormEditAgent}},[_vm._v("Uredi")])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }